<template>
  <center>
    <h1>404 Page not found</h1>
    <p>
      <a href="/">กลับสู่หน้าหลัก</a>
    </p>
  </center>
</template>
  
  <script>
export default {
  name: "NotFound",
};
</script>
  
  <style scoped>
center {
  height: calc(100% - 124px);
  display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

h1 {
  color: var(--border);
  font-size: 2em;
}
</style>