import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#185787',
        secondary: '#D4E6FA',
        bgcolor: '#F0F7FF',
        subcolor: '#92929D',
        btncolor: '#175787',
        grayoil: '#C6B8AB'
      },
    },
  },
})