import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueApexCharts from 'vue-apexcharts'
import LongdoMap from 'longdo-map-vue'
import VCalendar from 'v-calendar'
import authentication from '@/plugins/keycloak-plugin'
import VueCookies from 'vue-cookies'
// import VueFbCustomerChat from 'vue-fb-customer-chat'

// Vue.use(VueFbCustomerChat, {
//   page_id: '1891653577729026', //  change 'null' to your Facebook Page ID,
//   theme_color: '#333333', // theme color in HEX
//   locale: 'en_US', // default 'en_US'
// })

Vue.use(LongdoMap, {
  load: {
    apiKey: 'dbf68e6439cd7a7393745565779cfa05'
  }
})
Vue.use(authentication)
Vue.use(VueCookies, { expires: '1d'})

Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)

Vue.use(VCalendar, {
  componentPrefix: 'vc',
});

Vue.config.productionTip = false
Vue.use(VueAxios, axios)

Vue.$keycloak
  .init({
    checkLoginIframe: false, onLoad: 'check-sso'
  })
  // .init({ onLoad: 'check-sso' })
  .then(() => {
    new Vue({
      router,
      vuetify,
      render: h => h(App)
    }).$mount('#app')
  })